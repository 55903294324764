<template>

  <div>
    <b-card
      class="mb-0"
    >
      <div class="mb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4 class="card-title mb-0 pt-50">
              Invoice Management System</h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex"
          >
            <b-form-group
              v-if="employers.length>1"
              label="Parent Type"
              label-cols-md="5"
              class="w-50 text-right mb-0"
            >
              <v-select
                v-model="type"
                :options="employers"
                :reduce="item => item.parent_type_id"
                label="parent_type"
                :clearable="false"
                placeholder="Select"
                @input="onTypeChanged()"
              />
            </b-form-group>
            <b-form-group
              label="Month"
              label-cols-md="5"
              class="w-50 text-right mb-0"
            >
              <b-input-group>
                <flat-pickr
                  v-model="month"
                  class="form-control"
                  :config="$store.state.maxMonthConfig"
                  placeholder="MM-YYYY"
                  :clear-button="true"
                  @on-change="monthChanged"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeMonthDate"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="text-right"
          >
            <b-button
              variant="primary"
              :to="{ name: 'invoices-new'}"
            >
              <span class="text-nowrap">Add Invoice</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6 ml-auto"
            class="col-xxl-4"
          >
            <b-alert
              v-if="showRejected"
              variant="warning"
              show
              class="mt-1 mb-0"
            >
              <div class="alert-body">
                <span>
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="16"
                  />
                  You have a rejected invoice, please click
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                  to make corrections.
                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

      </div>

      <vue-good-table
        :columns="columns"
        :rows="invoicesResponse.invoices"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          v-if="show_payment_terms_days"
          slot="table-actions-bottom"
        >
          <p
            align="right"
            class="pt-1 mr-2"
          >
            Payment terms are Net <strong>{{ payment_terms_days }}</strong> from the date of receipt of a correct invoice
          </p>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Currency -->
          <span v-if="props.column.field === 'cellRendererCurrency'">
            <span>{{ props.row.amount| currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </span>

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="btn-action"
          >
            <span>
              <b-link
                id="view"
                class="ml-1"
                :to="{ name: 'invoices-view', params: { id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-primary"
                ><feather-icon icon="EyeIcon" />
                </b-badge>
              </b-link>
              <b-link
                v-if="props.row.editable"
                id="Edit"
                class="ml-2"
                :to="{ name: 'invoices-edit', params: { id: props.row.id } }"
              >
                <b-badge
                  pill
                  variant="light-warning"
                ><feather-icon icon="Edit2Icon" />
                </b-badge>
              </b-link>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="invoicesResponse.count"
                first-number
                last-number
                align="right"
                :per-page="1"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BLink, BBadge, BPagination, BFormGroup, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import User from '@/js/user'
import Invoices from '@/js/invoices'
import Constants from '@/js/constant'
import moment from 'moment'
import Auth from '@/auth/authService'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    VueGoodTable,
    flatPickr,

    vSelect,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      type: null,
      parent_type: null,
      month_date: null,
      showRejected: false,
      isJobExist: false,
      show_payment_terms_days: false,
      payment_terms_days: null,
      employers: [],
      month: moment().subtract(1, 'months').format('MMM-YYYY'),
      invoicesResponse: {
        invoices: [],
        count: 1,
      },
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Invoice',
          field: 'invoice',
          width: '240px',
          sortable: false,
        },
        {
          label: 'Consultant',
          field: 'consultants',
          sortable: false,
        },
        {
          label: 'Period',
          field: 'period',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Amount ($)',
          field: 'cellRendererCurrency',
          width: '180px',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          width: '180px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          width: '120px',
          sortable: false,
        },
      ],
      rows: [],
      configs: {
        monthSelect: {
          plugins: [
            monthSelectPlugin({
              shorthand: true,
              dateFormat: 'M-Y',
              altFormat: 'F, Y',
            }),
          ],
        },
        start: {
          format: 'MMM-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.page
      },
      set(val) {
        this.$store.state.query.page = val
        Invoices.getInvoices(this)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Approved      : 'light-success',
        Paid          : 'light-success',
        paid          : 'light-success',
        Corrected     : 'light-info',
        Submitted     : 'light-primary',
        Rejected      : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.searchQuery = this.$store.state.query.query
    this.invoicesResponse.count = this.$store.state.query.page
    if (User.profile() != null) {
      this.isJobExist = (User.profile().timesheet_provider_parents.length > 0 || User.profile().invoice_parents.length > 0) && User.profile().job_parent.length > 0
    }
    this.employers = User.profile().invoice_parents
    if (this.employers.length === 1) {
      this.type = this.employers[0].parent_type_id
    }
    this.parent = this.$store.state.selectedUser
    if (this.parent !== null) {
      this.type = this.parent.parent_type_id
    }
    this.month = this.$store.state.query.month
    if (this.month !== '') {
      this.month = moment().subtract(1, 'months').format('MMM-YYYY')
    }
    if (this.month !== null && this.month !== '') {
      this.month_date = Constants.getEprmMonthDateFromString(`${moment(this.month, 'MMM-YYYY').format('MM')}-01-${moment(this.month, 'MMM-YYYY').format('YYYY')}`)
    }
    if (User.profile().invoice_exist) {
      Invoices.getInvoices(this)
    } else if (User.profile().timesheet_exist) {
      window.location.hash = '#/timesheets'
    } else {
      Auth.logout()
    }
  },
  methods: {
    onTypeChanged() {
      this.invoices = []
      this.recordsCount = 1
      this.showRejected = false
      this.show_payment_terms_days = false
      this.$store.state.query.selectedUser = this.parent
      Invoices.getInvoices(this)
    },
    monthChanged(config, date) {
      if (date === '') {
        this.removeMonthDate()
        return
      }
      this.month = date
      this.month_date = Constants.getEprmMonthDateFromString(`${moment(this.month, 'MMM-YYYY').format('MM')}-01-${moment(this.month, 'MMM-YYYY').format('YYYY')}`)
      this.currentPage = 1
      this.recordsCount = 0
      this.currentPageRecordsCount = 0
      this.nextPageEnabled = false
      this.prevPageEnabled = false
      this.$store.state.query.month = this.month
      Invoices.getInvoices(this)
    },
    removeMonthDate() {
      this.month_date = null
      this.currentPage = 1
      this.month = null
      this.recordsCount = 0
      this.currentPageRecordsCount = 0
      this.nextPageEnabled = false
      this.prevPageEnabled = false
      this.$store.state.query.month = ''
      Invoices.getInvoices(this)
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setInvoices(invoicesResponse) {
      this.invoicesResponse = invoicesResponse
      this.showRejected = invoicesResponse.rejected_exist
      this.payment_terms_days = invoicesResponse.payment_terms_days
      this.show_payment_terms_days = invoicesResponse.show_payment_terms_days
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
